import request from "@/utils/request";

/**
 * 获取字典数据
 * @param key
 * @returns {Promise<*|null>}
 */
export async function getConfig(key) {
  try {
    const res = await request({
      url: `/api/Category/QueryByKey?key=${key}`,
      method: "get"
    });
    return res.response;
  } catch (e) {
    return null;
  }
}
