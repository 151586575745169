<template>
  <div class="page-nav-wrapper">
    <div class="nav" :style="{ left: -left + 'px' }">
      <div class="nav-contaner nav-left">
        <!-- logo -->
        <router-link to="/">
          <img class="logo" src="../../assets/images/logo.svg" alt="" />
        </router-link>
      </div>
      <!-- 中间的导航 -->
      <div class="nav-contaner nav-right">
        <NavLinks class="nav-links" />
        <div class="holder"></div>
        <!-- <svg-icon icon-class="search" class="icon-search" /> -->
      </div>
      <!-- 搜索框 -->
      <!-- <SearchInput /> -->
    </div>
  </div>
</template>

<script>
import NavLinks from "@/components/PageNav/NavLinks";
// import SearchInput from "@/components/PageNav/SearchInput";
// import { mapState } from "vuex";

export default {
  data() {
    return {
      // left: 控制导航栏随横向滚动条移动
      left: 0
    };
  },
  components: {
    NavLinks
    // SearchInput
  },
  mounted() {
    // 导航栏随横向滚动条移动
    window.addEventListener(
      "scroll",
      () => {
        let scrollLeft =
          document.documentElement.scrollLeft || document.body.scrollLeft;
        this.left = Math.floor(scrollLeft);
      },
      true
    );
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/var.scss";
svg {
  color: #004898;
}
.page-nav-wrapper {
  background-color: #fff;
  height: 90px;
  width: 100%;
  max-width: $page-max-width;
  position: fixed;
  top: 0;
  z-index: 9999;
  box-shadow: 0 1px 0px 0 rgb(224, 224, 224);
  font-size: 14px;
  .nav {
    width: $page-content-width;
    height: 100%;
    margin: 0 auto;
    padding: 0 10px;
    position: relative;
    .logo {
      height: 61px;
      color: #004898;
    }
  }

  .nav-contaner {
    height: 100%;
    display: inline-flex;
    align-items: center;
  }
  .holder {
    width: 100px;
  }
  .nav-right {
    float: right;
    height: 100%;

    .nav-links {
      margin-right: 15px;
    }
  }
}
// 搜索
</style>
