<template>
  <v-app class="page-wrapper">
    <page-nav />
    <router-view></router-view>
    <page-footer />
    <!-- <to-top /> -->
  </v-app>
</template>

<script>
import PageNav from "@/components/PageNav";
import PageFooter from "@/components/PageFooter";
// import ToTop from "@/components/ToTop";

export default {
  name: "App",
  components: {
    PageNav,
    PageFooter
    // ToTop
  },
  mounted() {
    let body = document.querySelector("body");
    // console.log(body);
    body.addEventListener(
      "click",
      e => {
        try {
          let result = e.path.find(element => {
            return element.id == "search";
          });
          if (!result && this.$store.state.isShowSearchInput) {
            // 当不存在关键词才隐藏输入框
            let searchKeyword = this.$store.state.searchKeyword;
            !searchKeyword && this.$store.commit("changeSearchInputStatus");
          }
        } catch (err) {
          //
        }
      },
      false
    );
  }
};
</script>

<style lang="scss">
@import "./styles/var.scss";

// @font-face {
//   font-family: "AlibabaPuHuiTi-Regular";
//   src: url("./assets/fonts/Alibaba-PuHuiTi-Regular.ttf");
// }
@font-face {
  // 多个src要用逗号隔开，不然后面会覆盖前面
  font-family: "Impact";
  src: url("./assets/fonts/impact/impact.eot"),
    url("./assets/fonts/impact/impact.ttf");
}
// 阿里字体图标
@font-face {
  font-family: "iconfont";
  src: url("./assets/fonts/iconfont/iconfont.eot");
  src: url("./assets/fonts/iconfont/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/iconfont/iconfont.woff2") format("woff2"),
    url("./assets/fonts/iconfont/iconfont.woff") format("woff"),
    url("./assets/fonts/iconfont/iconfont.ttf") format("truetype"),
    url("./assets/fonts/iconfont/iconfont.svg#iconfont") format("svg");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: #f5f5f5;
  scroll-behavior: smooth;
  color: #1d1d1f;
}
.page-wrapper {
  // font-family: "AlibabaPuHuiTi-Regular" !important;
  font-family: "Ya Hei" !important;
  min-width: $page-content-width;
  max-width: $page-max-width;
  margin: 0 auto;
}
</style>
