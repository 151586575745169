import request from "@/utils/request";

/**
 * 获取首页新闻列表
 * @returns {Promise<*|*[]>}
 */
export async function getHomePageNews() {
  try {
    const res = await request({
      url: "/api/FrontArticle/GetHomepageNew",
      method: "get"
    });
    return res.response;
  } catch (e) {
    return [];
  }
}

/**
 * 获取文章详情
 * @param id
 * @returns {Promise<*|null>}
 */
export async function getNewInfo(id) {
  try {
    const res = await request({
      url: `/api/FrontArticle/GetInfo?articleId=${id}`,
      method: "get"
    });
    return res.response;
  } catch (e) {
    return null;
  }
}

/**
 * 分页获取文章列表
 * @param data
 * @returns {Promise<*|null>}
 */
export async function queryPage(data) {
  try {
    const res = await request({
      url: "/api/FrontArticle/ArticlePage",
      method: "post",
      data
    });
    return res.response;
  } catch (e) {
    return null;
  }
}

/**
 * 获取最新文章
 * @param type
 * @param row
 * @returns {Promise<*|*[]>}
 */
export async function getNews(type, row) {
  try {
    const res = await request({
      url: `/api/FrontArticle/GetNews?typeId=${type}&row=${row}`,
      method: "get"
    });
    return res.response;
  } catch (e) {
    return [];
  }
}

export async function getHomeProject() {
  try {
    const res = await request({
      url: "/api/FrontArticle/GetHomepageProject",
      method: "get"
    });
    return res.response;
  } catch (e) {
    return [];
  }
}
