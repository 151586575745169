<template>
  <ul class="navlinks" @mouseleave="handleMouseleave()">
    <li
      v-for="(link, index) in links"
      :key="index"
      :class="{
        nothover: hoverTab != index,
        hover: hoverTab === index,
        active: activeIndex == index
      }"
      @mouseenter="handleMouseenter(index, $event)"
    >
      <router-link :to="link.path" class="nav-item-link" ref="linkItem">{{
        link.name
      }}</router-link>
      <ul class="subnav" v-if="link.children.length !== 0">
        <li v-for="(item, index) in link.children" :key="index">
          <router-link :to="item.path">{{ item.name }}</router-link>
        </li>
      </ul>
    </li>
    <div
      class="animation"
      ref="animation"
      :style="{ left: hoverTab * 100 + 'px' }"
    ></div>
  </ul>
</template>

<script>
import { getNews } from "@/api/frontArticle";

export default {
  data() {
    return {
      hoverTab: "init",
      links: [
        {
          name: "首页",
          path: "/",
          children: []
        },
        {
          name: "关于我们",
          path: "/about-us",
          children: [
            // { name: "我们是谁", path: "/about-us/whoweare" },
            // { name: "理事会", path: "/about-us/council" },
            // { name: "社会影响", path: "/about-us/influence" },
            // { name: "加入我们", path: "/about-us/join-us" }
          ]
        },
        {
          name: "新闻资讯",
          path: "/news",
          children: [
            // { name: "新闻资讯", path: "/news/information" },
            // { name: "项目故事", path: "/news/story" }
          ]
        },
        {
          name: "公益项目",
          path: "/projects",
          children: [
            /*{ name: "乡村助老员支持计划", path: "/projects/project-details/1" },
            { name: "小红花梦想", path: "/projects/project-details/2" },
            { name: "用心聆听·为爱奔跑", path: "/projects/project-details/3" },
            { name: "科技助老", path: "/projects/project-details/4" }*/
          ]
        },
        // {
        //   name: "品牌活动",
        //   path: "/activities",
        //   children: [{ name: "长益之旅", path: "/activities/long-journey" }]
        // },
        {
          name: "公开透明",
          path: "/announcements",
          children: [
            { name: "年度报告", path: "/announcements/annual-report" },
            { name: "审计报告", path: "/announcements/audit-report" },
            { name: "项目报告", path: "/announcements/project-report" },
            {
              name: "资质规章",
              path: "/announcements/regulations"
            }
          ]
        },
        {
          name: "我们的伙伴",
          path: "/partners",
          children: []
        }
        // {
        //   name: "搜索结果",
        //   path: "/search-result",
        //   children: []
        // }
      ]
    };
  },
  computed: {
    // 当前所在路由
    activeIndex() {
      const { path } = this.$route;
      // console.log(path);
      // this.$store.commit("updateCurrentRoute", path)
      const index = this.links.findIndex(o => {
        return o.path === "/" + path.split("/")[1];
      });
      if (index < 0) {
        return 0;
      }
      return index;
    }
  },
  mounted() {
    this.getNews();
  },
  methods: {
    handleMouseenter(index) {
      this.hoverTab = index;
      this.$refs.animation.style.left = 100 * index + "px";
    },
    handleMouseleave() {
      this.hoverTab = this.activeIndex;
      this.$refs.animation.style.left = 100 * this.activeIndex + "px";
    },
    async getNews() {
      const res = await getNews(15, 4);
      if (res.length) {
        for (let i = 0; i < this.links.length; i++) {
          if (this.links[i].path === "/projects") {
            this.links[i].children = res.map(o => {
              return {
                name: o.title,
                path: `/projects/project-details/${o.id}`
              };
            });
            break;
          }
        }
      }
    }
  },
  updated() {
    if (this.hoverTab == "init") {
      this.hoverTab = this.activeIndex;
    }
  },
  watch: {
    activeIndex(newValue) {
      if (newValue === 0) {
        this.hoverTab = "init";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.navlinks {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0;
  > li {
    width: 100px;
    height: 95px;
    position: relative;
    display: inline-flex;
    align-items: center;
    a {
      color: #333;
    }
    .subnav {
      border-radius: 5px;
      display: none;
      position: absolute;
      min-width: 160px;
      background-color: #fff;
      text-align: center;
      top: 95px;
      left: 50%;
      margin-left: -75px;
      line-height: 40px;
      padding: 0 10px;
      box-shadow: 0px 0px 2px 0px rgb(207, 207, 207);
      box-sizing: border-box;
      a {
        text-align: center;
        box-sizing: border-box;
        width: 130px;
        height: 100%;
        display: inline-block;
        color: #333;
      }
    }
  }
  li:hover {
    color: #fff;
    .subnav {
      display: block;
      cursor: pointer;
    }
    .subnav li:hover a {
      border-radius: 5px;
      color: #fff;
      background-color: #004898;
    }
  }
  a {
    display: inline-block;
    text-decoration: none;
    width: 100px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    text-align: center;
    border-radius: 13px;
  }

  li.hover .nav-item-link {
    color: #fff;
  }
  li.nothover .nav-item-link {
    color: #333;
  }
  li.nothover.active .nav-item-link {
    color: #333;
  }
  li.active .nav-item-link {
    color: #fff;
  }
  .animation {
    position: absolute;
    top: 50%;
    margin-top: -17.5px;
    left: 0;
    width: 100px;
    height: 35px;
    background-color: #004898;
    border-radius: 10px;
    z-index: -1;
    transition: all ease 0.2s;
  }
}
</style>
