<template>
  <div id="detail-wrapper">
    <!-- <div class="left">
      <div class="info-title">
        {{ this.$route.params.id }}
        长益基金会 | 携手长安慈，“关怀山村留守老人”公益项目落地陕西佳县
      </div>
      <div class="box">
        <div class="date">
          <img src="@/assets/images/js-sc.jpg" alt="" />
          <span>: 2020-07-24</span>
        </div>
        <div class="publisher">
          <img src="@/assets/images/fw-sc.jpg" alt="" />
          <span>: 长益基金宣传部</span>
        </div>
        <div class="visit-count">
          <img src="@/assets/images/zz-sc.jpg" alt="" />
          <span>: 19600</span>
        </div>
      </div>
      <div class="info-content">
        <div>
          在中国银行保险业监督管理委员会陕西监管局的密切指导和大力支持下，由陕西省银行业协会和陕西省保险行业协会牵头发起，自2020年7月，20余家陕西省内金融机构陆续捐款，全部资金用于委托长安国际信托股份有限公司设立“长安慈——脱贫攻坚关爱农村三留守群体慈善信托”（以下简称关爱三留守慈善信托）。首期项目重点聚焦农村留守老人、留守妇女等弱势群体，通过资金支持，对留守妇女进行专业技能培训后就近照顾留守老人。首期项目重点聚焦农村留守老人、留守妇女等弱势群体，通过资金支持，对留守妇女进行专业技能培训后就近照顾留守老人。
        </div>
        <div>
          佳县位于陕西省东北部黄河中游西岸，榆林市东南部。全县户籍人口有26.97万，贫困人口较多。以乔家枣坪村为例，该村自然条件差，900多人的村子，就有建档立卡贫困户44户，共计114人。
        </div>
        <div class="imgs">
          <div class="img-item">
            <div class="img-wrapper">
              <img src="@/assets/images/influence3.jpg" alt="" />
            </div>
            <div class="caption">
              “关怀深山留守老人”项目的陪伴人员李立平给李正相老人剪头发
            </div>
          </div>
          <div class="img-item">
            <div class="img-wrapper">
              <img src="@/assets/images/influence3.jpg" alt="" />
            </div>
            <div class="caption">
              “关怀深山留守老人”项目的陪伴人员李立平给李正相老人剪头发
            </div>
          </div>
        </div>
        <div>就有建档立卡贫困户44户，共计114人。</div>
        <div>
          根据关爱三留守慈善信托决策委员会的决议，长益基金会将在佳县开展“关怀山村留守老人”项目，该项目计划以资助和赋能为主，为民政工作和扶贫工作提供个性化补充，以项目带动本土力量参与，以点带面，激发本土能量，最终形成本土资源循环，从而系统性解决社会问题。根据当地实际情况与当地政府部门达成的共识及合作，培育本村村民并优先培育留守妇女成为农村养老社工，为留守老人提供养老服务，开展社区公益活动链接三大留守群体和社区公众，促进当地社区文明新风。
        </div>
        <div class="imgs img2">
          <img src="@/assets/images/home_b3.jpg" alt="" />
        </div>
      </div>
    </div> -->
    <div class="left">
      <div v-if="newInfo" v-html="newInfo.content" class="v-html"></div>
      <!--      <div-->
      <!--        v-for="(item, index) in vmData[this.$route.params.id - 1]"-->
      <!--        :key="index"-->
      <!--      >-->
      <!--        <div v-if="item.type === 'title'" class="title">{{ item.value }}</div>-->
      <!--        <img v-if="item.type === 'img'" :src="item.img" alt="" class="img" />-->
      <!--        <span v-if="item.type === 'time'" class="time">{{ item.value }}</span>-->
      <!--        <div v-if="item.type === 'text'" class="text">{{ item.value }}</div>-->
      <!--        <div v-if="item.type === 'remark'" class="remark">{{ item.value }}</div>-->
      <!--      </div>-->
    </div>
    <div class="right">
      <div class="latest">
        <div>最新动态</div>
        <ul>
          <li v-for="(item, index) in latestInfoList" :key="index">
            <router-link :to="{ name: 'Details', params: { id: item.id } }">
            </router-link>
            <div class="img" v-if="item.coverImg">
              <img :src="item.coverImg" alt="" />
            </div>
            <div class="info-title">{{ item.title }}</div>
            <div class="publish-date">{{ item.date }}</div>
            <div class="info-abstract">{{ item.profile }}</div>
          </li>
        </ul>
      </div>

      <router-link class="back-to-list" :to="{ name: 'List' }">
        <img src="@/assets/images/back.png" alt="" />
        <span>返回新闻列表</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { getNewInfo, getNews } from "@/api/frontArticle";
import moment from "moment";

export default {
  name: "Details",
  data() {
    return {
      newInfo: null,
      latestInfoList: []
    };
  },
  computed: {
    newId() {
      return this.$route.params.id;
    }
  },
  watch: {
    newId: {
      deep: true,
      immediate: true,
      handler(x) {
        if (x) {
          this.getNewsMessage(x);
        }
      }
    }
  },
  mounted() {
    this.getNews();
  },
  methods: {
    async getNewsMessage(id) {
      const res = await getNewInfo(id);
      if (res) {
        this.newInfo = res;
      }
    },
    async getNews() {
      const res = await getNews(14, 3);
      this.latestInfoList = res.map(o => {
        return {
          ...o,
          date: moment(o.updateTime).format("YYYY.MM.DD")
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#detail-wrapper {
  width: 1240px;
  margin: 0 auto 170px;
  display: flex;
}
.left {
  width: 980px;
  padding: 0 115px 0 60px;
  font-size: 16px;
  line-height: 32px;
  .info-title {
    font-size: 30px;
  }
  .box {
    display: flex;
    margin: 40px 0 50px 0;
    > div {
      margin-right: 40px;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
  }
  .info-content {
    div {
      text-indent: 2em;
      line-height: 34px;
    }
    .imgs {
      width: 100%;
      height: 290px;
      // border: 1px solid gray;
      margin: 35px auto;
      display: flex;
      justify-content: space-around;
      .img-item {
        width: 386px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        .img-wrapper {
          width: 100%;
          height: 232px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          img {
            width: 100%;
          }
        }
        .caption {
          text-align: center;
          text-indent: 0;
        }
      }
    }
    .img2 {
      height: 460px;
      margin-bottom: 0;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
  .img {
    max-width: 805px;
  }
  .title {
    font-size: 30px;
    text-align: center;
    margin: 30px 0;
  }
  .text {
    text-indent: 2em;
  }
  .remark {
    text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .time {
    font-size: 14px;
  }
}
.right {
  width: 260px;
  background-color: #f5f5f5;
  position: relative;
  line-height: 30px;
  .latest {
    > div {
      font-size: 30px;
      margin: 30px;
    }
    ul {
      width: 100%;
      padding: 0 10px;
    }
    li {
      width: 100%;
      padding: 0 20px;
      border-bottom: 1px solid #ccc;
      cursor: pointer;
      position: relative;
      a {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
      }
      .img {
        width: 192px;
        height: 118px;
        margin: 16px 0;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .info-title {
        margin: 16px 0;
      }
      .publish-date {
        margin: 16px 0;
        color: #999;
        font-size: 14px;
      }
      .info-abstract {
        margin: 16px 0;
        font-size: 14px;
      }
    }
    li:last-child {
      border-bottom: none;
    }
  }
  .back-to-list {
    width: 100%;
    height: 70px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #004898;
    color: #fff;
    line-height: 70px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 20px;
    }
  }
}
</style>
<style lang="scss">
.v-html {
  img {
    max-width: 100% !important;
  }
}
</style>
