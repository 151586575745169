/**
 * 根据key获取配置值
 * @param data
 * @param key
 * @returns {*|string}
 */
export function formatConfig(data, key) {
  try {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return data[i].value;
      }
    }
    return "";
  } catch (e) {
    return "";
  }
}
