/*
 * @Description:
 * @Author: zx
 * @Date: 2020-06-12 10:05:16
 * @LastEditors: zx
 * @LastEditTime: 2020-09-15 10:59:22
 */
import axios from "axios";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
});

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    return response.data;
  },
  error => {
    return Promise.reject(error);
  }
);

export default service;
