import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 是否显示搜索框
    isShowSearchInput: false,
    // 搜索关键词
    searchKeyword: "",
    // 当前路由
    currentRoute: "/"
  },
  mutations: {
    changeSearchInputStatus(state) {
      state.isShowSearchInput = !state.isShowSearchInput;
    },
    updateSearchKeyword(state, keyword) {
      state.searchKeyword = keyword;
    },
    clearInputValue(state) {
      state.searchKeyword = "";
    },
    updateCurrentRoute(state, route) {
      state.currentRoute = route;
    }
  },
  actions: {},
  modules: {}
});
