<template>
  <footer class="page-footer">
    <div class="footer-wrapper">
      <div class="footer-row-1">
        <div class="site-map">
          <!-- 关于我们 -->
          <ul>
            <li class="site-root">
              <router-link to="/about-us/whoweare">关于我们</router-link>
            </li>
            <li>
              <router-link to="/about-us/whoweare">我们是谁</router-link>
            </li>
            <li>
              <router-link to="/about-us/council">发起人</router-link>
            </li>
            <!-- <li>
              <router-link to="/about-us/influence">社会影响</router-link>
            </li>
            <li>
              <router-link to="/about-us/join-us">加入我们</router-link>
            </li> -->
          </ul>
          <!-- 新闻资讯 -->
          <ul>
            <li class="site-root">
              <router-link to="/news/information">新闻资讯</router-link>
            </li>
            <li>
              <router-link to="/news/information">新闻资讯</router-link>
            </li>
            <!-- <li>
              <router-link to="/news/story">项目故事</router-link>
            </li> -->
          </ul>
          <!-- 公益项目 -->
          <ul>
            <li class="site-root">
              <router-link to="/projects/home-vertical">公益项目</router-link>
            </li>
            <li v-for="(item, index) in latestInfoList" :key="index">
              <router-link :to="'/projects/project-details/' + item.id">{{
                item.title
              }}</router-link>
            </li>
          </ul>
          <!-- 品牌活动 -->
          <!-- <ul>
            <li class="site-root">
              <router-link to="/activities/long-journey">品牌活动</router-link>
            </li>
            <li>
              <router-link to="/activities/long-journey">长益之旅</router-link>
            </li>
          </ul> -->
          <!-- 公开透明 -->
          <ul>
            <li class="site-root">
              <router-link to="/announcements/annual-report"
                >公开透明</router-link
              >
            </li>
            <li>
              <router-link to="/announcements/annual-report"
                >年度报告</router-link
              >
            </li>
            <li>
              <router-link to="/announcements/audit-report"
                >审计报告</router-link
              >
            </li>
            <li>
              <router-link to="/announcements/project-report"
                >项目报告</router-link
              >
            </li>
            <li>
              <router-link to="/announcements/regulations"
                >资质规章</router-link
              >
            </li>
          </ul>
          <!-- 我们的伙伴 -->
          <ul>
            <li class="site-root">
              <router-link to="/partners">我们的伙伴</router-link>
            </li>
          </ul>
        </div>
        <div class="qr-codes">
          <div class="qr-wrapper">
            <img :src="formatConfig('WeChat')" alt="" />
            <div>微信公众号</div>
          </div>
          <div class="qr-wrapper">
            <img :src="formatConfig('weibo')" alt="" />
            <div>新浪微博</div>
          </div>
        </div>
      </div>
      <div class="footer-row-2">
        版权声明 Copyright © 2019-{{ nowDate }} changyifoundation.org.cn
        版权所有 沪ICP备2020035127号
      </div>
    </div>
  </footer>
</template>

<script>
import { getConfig } from "@/api/category";
import { formatConfig } from "@/utils/formatConfig";
import { getNews } from "@/api/frontArticle";

export default {
  name: "Footer",
  data() {
    return {
      nowDate: new Date().getFullYear(),
      data: null,
      latestInfoList: []
    };
  },
  mounted() {
    this.getConfig();
    this.getNews();
  },
  methods: {
    async getConfig() {
      const res = await getConfig("ContactInformation");
      if (res !== null) {
        try {
          this.data = JSON.parse(res.value);
        } catch (e) {
          this.data = [];
        }
      }
    },
    formatConfig(key, data = this.data) {
      return formatConfig(data, key);
    },
    async getNews() {
      const res = await getNews(15, 4);
      this.latestInfoList = res;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/var.scss";

.page-footer {
  background-color: #32373d;
  // height: 312px;
  display: flex;
  justify-content: center;
  align-items: stretch;

  .footer-wrapper {
    width: $page-content-width;
    // background-color: green;
    // padding: 0 40px;

    .footer-row-1 {
      // background-color: red;
      display: flex;
      align-items: stretch;

      .site-map {
        flex: 1 1 auto;
        // background-color: yellow;
        padding: 63px 0 33px 0;
        display: flex;

        ul {
          padding: 0;
          flex: 1 1 auto;
          text-align: center;
        }

        li {
          list-style: none;

          &:not(:last-of-type) {
            margin-bottom: 6px;
          }

          &.site-root {
            margin-bottom: 13px;

            a {
              font-size: 16px;
              font-weight: bold;
            }
          }

          a {
            text-decoration: none;
            color: #fff;
            font-size: 14px;
          }
        }
      }

      .qr-codes {
        flex: 0 0 255px;
        // background-color: blue;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: #fff;

        .qr-wrapper {
          text-align: center;
        }
      }
    }

    .footer-row-2 {
      // background-color: purple;
      height: 68px;
      width: 96%;
      margin-left: 38px;
      border-top: 2px solid #697179;
      font-size: 14px;
      color: #697179;
      padding-top: 20px;
    }
  }
}
</style>
