import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/index.scss";
import "./assets/icons";
import vuetify from "./plugins/vuetify";

// echarts
// import echarts from "echarts";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

Vue.config.productionTip = false;

// 跳转到另一个页面时从顶部开始
router.beforeEach((to, from, next) => {
  // 直接修改下面属性会导致在IE浏览器下出现一个错误：
  // "TypeError: strict 模式下不允许分配到只读属性 ..."
  try {
    // chrome
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
    // safari
    window.pageYOffset = 0;
  } catch (e) {
    //
  }
  next();
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
