import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Details from "@/views/news/information/Details";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home/index.vue")
  },
  {
    path: "/about-us",
    name: "About",
    component: () => import("@/views/about-us/index.vue"),
    redirect: { name: "WhoWeAre" },
    children: [
      {
        path: "whoweare",
        name: "WhoWeAre",
        component: () => import("@/views/about-us/who-we-are/index.vue")
      },
      {
        path: "council",
        name: "Council",
        component: () => import("@/views/about-us/council/index.vue")
      },
      {
        path: "influence",
        name: "Influence",
        component: () => import("@/views/about-us/influence/index.vue")
      },
      {
        path: "join-us",
        name: "JoinUs",
        component: () => import("@/views/about-us/join-us/index.vue")
      }
    ]
  },
  {
    path: "/news",
    name: "News",
    component: () => import("@/views/news/index.vue"),
    redirect: { name: "InformationIndex" },
    children: [
      {
        path: "information",
        name: "InformationIndex",
        component: () => import("@/views/news/information/index.vue"),
        redirect: { name: "List" },
        children: [
          {
            path: "list",
            name: "List",
            component: () => import("@/views/news/information/List.vue")
          },
          {
            path: "details/:id",
            name: "Details",
            // component: () => import("@/views/news/information/Details.vue")
            component: Details
          }
        ]
      },
      {
        path: "story",
        name: "Story",
        component: () => import("@/views/news/story/index.vue")
      }
    ]
  },
  {
    path: "/projects",
    name: "Projects",
    component: () => import("@/views/projects/index.vue"),
    redirect: { name: "HomeVertical" },
    children: [
      {
        path: "home-vertical",
        name: "HomeVertical",
        component: () => import("@/views/projects/HomeVertical.vue")
      },
      {
        path: "home-grid",
        name: "HomeGrid",
        component: () => import("@/views/projects/HomeGrid.vue")
      },
      {
        path: "project-details/:id",
        name: "ProjectDetails",
        component: () => import("@/views/projects/ProjectDetails.vue")
      }
    ]
  },
  // {
  //   path: "/activities",
  //   name: "Activities",
  //   component: () => import("@/views/activities/index.vue"),
  //   redirect: { name: "LongJourney" },
  //   children: [
  //     {
  //       name: "LongJourney",
  //       path: "long-journey",
  //       component: () => import("@/views/activities/LongJourney.vue")
  //     }
  //   ]
  // },
  {
    path: "/announcements",
    name: "Announcements",
    component: () => import("@/views/announcements/index.vue"),
    redirect: { name: "AnnualReport" },
    children: [
      {
        name: "AnnualReport",
        path: "annual-report",
        component: () => import("@/views/announcements/AnnualReport.vue")
      },
      {
        name: "AuditReport",
        path: "audit-report",
        component: () => import("@/views/announcements/AuditReport.vue")
      },
      {
        name: "ProjectReport",
        path: "project-report",
        component: () => import("@/views/announcements/ProjectReport.vue")
      },
      {
        name: "Regulations",
        path: "regulations",
        component: () => import("@/views/announcements/Regulations.vue")
      }
    ]
  },
  {
    path: "/partners",
    name: "Partners",
    component: () => import("@/views/partners/index.vue")
  },
  {
    path: "/search-result",
    name: "SearchResult",
    component: () => import("@/views/search-result/index.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from) => {
    const toPath = to.fullPath.split("/");
    const fromPath = from.fullPath.split("/");
    if (toPath.length === fromPath.length) {
      for (let i = 0; i < toPath.length - 1; i++) {
        if (toPath[i] !== fromPath[i]) {
          return { y: 0 };
        }
      }
      return { y: window.scrollY };
    }
    return { y: 0 };
  }
});

export default router;
